import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import Metadata from '../components/metadata';
import GenericContent from '../components/generic-content';

const GenericContentPage = ({ data }) => {
  const { mdx: page } = data;
  const { frontmatter: metadata, body } = page;

  return (
    <Layout>
      <Metadata pageData={metadata} />
      <GenericContent
        image={metadata.image}
        title={metadata.title}
        body={body}
      />
    </Layout>
  );
};

GenericContentPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }).isRequired,
};

export default GenericContentPage;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
